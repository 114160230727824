import React from "react";
import SearchBox from "Components/SearchBox/index.js";
import Footer from "Components/Footer/index.js";
import Results from "Components/Results/index.js";
import Result from "Components/Result/index.js";
import Header from "Components/Result/Header/index.js";
import ResultSectionSwapper from "Components/ResultSectionSwapper/index.js";
import Pagination from "Components/Pagination/index.js";
import HomePage from "Components/HomePage/index.js";
import MainLogo from "Components/MainLogo/index.js";
import { URLS } from "Constants/urls.js"
import getError from "Utils/errorHandling.js";
import "./styles.scss";

const App = class extends React.Component {
	constructor(props) {
        super(props);
        this.dynamicDataOrderId = "f7bac23c-1c0d-4118-88c4-984dcb99813a"; // prod
        // this.dynamicDataOrderId = "1addcb85-6357-48e5-b71e-4cb1effd8dde"; // test
        this.state = {
            id: null,
            results: null,
            error: null,
            searching: false,
            bootstrap: true,
            unauthorised: false,
            get: false,
            getAll: true,
            page: 0,
            init: false,
            supervisor: false,
            dynamicDataOrder: null,
            auth: null,
            user: null,
            apiRoute: `${URLS}/order`,
        };

        window.Gnatta.on("init", () => {
            window.Gnatta.on("interaction_loaded", ({ id, dynamicData}) => {
                let dynamicDataOrder = dynamicData.filter(x => x.id === this.dynamicDataOrderId)[0];
			    this.setState({ apiRoute: `${this.state.apiRoute}/${id}/`, id, dynamicDataOrder });
                window.Gnatta.user.get().then((user) => {
                    if(user.roles && user.roles.includes("ADMIN")) {
                        let userName = user.id;
                        if (userName.indexOf("@") > -1) {
                            // Remove everything before the | and everything after the @
                            userName = userName.split("|")[1].split("@")[0];
                        }
                        this.setState({ supervisor: true, user: userName });
                    }

                    this.initalLoad();
                })
                .catch((err) => {
                    // ignore
                });
            });
        });

        window.Gnatta.on("dynamicdata_updated", dynamicData => {
            if (this.dynamicDataOrderId === dynamicData.id) {
                this.setState({ dynamicDataOrder: dynamicData });
                this.search(null, null, null, this.state.dynamicDataOrder.value.value.trim());
            }
        });
    }

    initalLoad = async () => {
        try {
            this.setState({ init: true });
            if(this.state.dynamicDataOrder != null && this.state.dynamicDataOrder.value.value.trim()) {
                this.search(null, null, null, this.state.dynamicDataOrder.value.value.trim());
            }
            
        } catch(err) {
            this.setState({ unauthorised: true, bootstrap: false, error: err });
        }
    }

    clear = () => this.setState({ results: null, firstName: null, lastName: null, orderNumber: null, error: null, bootstrap: true, getAll: true, get: false });

    changeSection = (section) => this.setState({ section });

    changePage = (page) => {
        this.setState({ results: null, searching: true, bootstrap: false, error: null });
        this.getAll(this.state.firstName, this.state.lastName, this.state.email, page);
    };

	search = async (firstName, lastName, email, orderNumber) => {
        this.setState({ results: null, searching: true, bootstrap: false, error: null });

        if (orderNumber) {
            this.get(orderNumber);
            this.setState({ orderNumber, firstName: null, lastName: null, email: null });
        } else {
            this.getAll(firstName, lastName, email);
            this.setState({ firstName, lastName, email, orderNumber: null });
        }
    };
    
    get = async (orderNumber) => {
        try {
            const results = await window.Gnatta.http.get(this.state.apiRoute + orderNumber, { headers: { "Accept": "application/json" }});
            const response = JSON.parse(results.body);
            if(response.error) {
                this.setState({
                    searching: false,
                    error: response.error,
                    getAll: true,
                    get: false,
                });
            } else {
                this.setState({
                    results: response,
                    searching: false,
                    getAll: false,
                    get: true,
                });
            }
        }
        catch(err) {
            this.setState({
                searching: false,
                error: getError(err),
                getAll: true,
                get: false,
            });
        };
    }

    getAll = async (firstName, lastName, email, page) => {
        try {
            const payload = {
                "FirstName": firstName, 
                "LastName": lastName, 
                "Email": email, 
                "OrderId": "", 
                "Page": page ? page : this.state.page,
            };
            const body = JSON.stringify(payload);
            const results = await window.Gnatta.http.post(this.state.apiRoute, { body });
            const response = JSON.parse(results.body);
            this.setState({ getAll: true, get: false });
            if (response.error) {
                this.setState({
                    searching: false,
                    error: response.error,
                });
            } else {
                this.setState({
                    results: response,
                    searching: false,
                });
            }
        }
        catch(err) {
            this.setState({
                searching: false,
                error: getError(err),
            });
        };
    }

	render() {
		return (
			<div className="container">
                <MainLogo />
                {/* if the user is authenticated display the search box */}
                {!this.state.unauthorised && this.state.init && this.state.getAll && <SearchBox search={this.search}/>}
                {/* if there is data in state, but there is no pagination data, a Get request was made */}
                {this.state.results && !this.state.results.pagination &&
                    <Header clear={this.clear} data={this.state.results}/>
                }
				{this.state.bootstrap
				 ? <HomePage />
				 : 
                <React.Fragment>
                    {/* if there is pagination data a GetAll request was made */}
                    {!this.state.orderNumber && 
                        <React.Fragment>
                            {this.state.results && this.state.results.pagination &&
                                <Pagination {...this.state.results.pagination} changePage={this.changePage} />
                            }
                            <Results
                                searching={this.state.searching}
                                search={this.search}
                                results={this.state.results}
                                error={this.state.error}
                            />
                        </React.Fragment>
                    }
                    {/* if there is an order number in state a Get request was made */}
                    {this.state.orderNumber &&
                        <React.Fragment>
                            <Result 
                                searching={this.state.searching}
                                result={this.state.results}
                                section={this.state.section}
                                isSupervisor={this.state.supervisor}
                                user={this.state.user}
                                error={this.state.error}
                            />
                            {this.state.results && <ResultSectionSwapper
                                change={this.changeSection}
                                section={this.state.section}
                            />}
                        </React.Fragment>
                    }
                </React.Fragment>}
				<Footer/>
			</div>
		)
	};
};

export default App;
