import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import CreateTicket from "Components/CreateTicket/index.js";
import LoyaltyClaim from "Components/LoyaltyClaim/index.js";
import { ReactComponent as Icon } from "Assets/Icons/Order.svg";
import "./styles.scss";

const Header = ({ data, clear }) => {
    const [ticketActive, setTicketActive] = useState(false);
    const [loyaltyActive, setLoyaltyActive] = useState(false);

    const onCreateTicket = (e) => {
        if(e) {
            e.preventDefault();
        }
        ticketActive ? setTicketActive(false) : setTicketActive(true);
    };

    const onLoyaltyClaim = (e) => {
        if(e) {
            e.preventDefault();
        }
        loyaltyActive ? setLoyaltyActive(false) : setLoyaltyActive(true);
    };

    return (
        <React.Fragment>
            {ticketActive && <CreateTicket isActive={ticketActive} order={data.code} callback={onCreateTicket} />}
            {/* {loyaltyActive && <LoyaltyClaim isActive={loyaltyActive} data={data} callback={onLoyaltyClaim} />} */}
            <div className="highlight-card">
                <div className="clear" onClick={() => clear()}>← Back to search</div>
                <div className="header">
                    <div className="icon">
                        <Icon />
                    </div>
                    #{data.code}
                    <button onClick={(e) => onCreateTicket(e)}>Create Ticket</button>
                </div>
                <div className="container">
                    {data.created &&
                        <div className="content">
                            <span>Created</span>
                            <div>{moment(new Date(data.created)).format("YYYY-MM-DD HH:mm:ss")}</div>
                        </div>
                    }
                    {data.deliveryMode &&
                        <div className="content">
                            <span>Delivery Mode</span>
                            <div>{data.deliveryMode.name}</div>
                        </div>
                    }
                    {data.originalOrder && 
                        <div className="content">
                            <span>Original Order No.</span>
                            <div>{data.originalOrder}</div>
                        </div>
                    }
                    {data.status &&
                        <div className="content">
                            <span>Order Status</span>
                            <div>{data.status}</div>
                        </div>
                    }
                    {data.statusDisplay &&
                        <div className="content">
                            <span>Display Order Status</span>
                            <div>{data.statusDisplay}</div>
                        </div>
                    }
                    {data.totalPrice &&
                        <div className="content">
                            <span>Currency</span>
                            <div>{data.totalPrice.currencyIso}</div>
                        </div>
                    }
                    {data.replacementOrderCode &&
                        <div className="content">
                            <span>Replacement Order No.</span>
                            <div>{data.replacementOrderCode}</div>
                        </div>
                    }
                    {data.loyaltyInfo && data.loyaltyInfo.cardNumber &&
                        <div className="content">
                            <span>Beauty Card No.</span>
                            <div>{data.loyaltyInfo.cardNumber}</div>
                        </div>
                    }
                    {data.totalEarnedPoints !== null &&
                        <div className="content">
                            <span>Earned Points</span>
                            <div>
                                {data.totalEarnedPoints}
                            </div>
                        </div>
                    }
                    {data.totalRedeemedPoints !== null &&
                        <div className="content">
                            <span>Total Redeemed Points</span>
                            <div>{data.totalRedeemedPoints}</div>
                        </div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

Header.propTypes = {
    data: PropTypes.object.isRequired,
    clear: PropTypes.func.isRequired,
};

export default Header;