import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";  
import Spinner from "Components/Spinner/index.js";
import Error from "Components/Error/index.js";
import getSection from "Utils/sectionMap.js";
import "./styles.scss";

const Result = ({ section, searching, result, isSupervisor, user, error}) => {
    const DisplayedSection = getSection(section);

    return (
        <div className="result-container">
            {searching && <Spinner/>}
            {error && <Error message={error}/>}
            {result && 
            <Scrollbars
                className="scrollbars"
            >
                <DisplayedSection data={result} isSupervisor={isSupervisor} user={user} />
            </Scrollbars>}
        </div>
    );
};

Result.propTypes = {
    section: PropTypes.number.isRequired,
    searching: PropTypes.bool.isRequired,
    isSupervisor: PropTypes.bool,
    user: PropTypes.string,
    result: PropTypes.object,
    error: PropTypes.string
};

Result.defaultProps = {
    section: 0,
    isSupervisor: false,
};

export default Result;