import React from "react";
import PropTypes from "prop-types";
import ReturnRequest from "Components/Cards/ReturnRequest";
import Consignment from "Components/Cards/Consignment";
import Ticket from "Components/Cards/Ticket";
import Customer from "Components/Cards/Customer";
import { ReactComponent as ConsignmentsIcon } from "Assets/Icons/Consignment.svg";
import { ReactComponent as CustomerIcon } from "Assets/Icons/Customer.svg";
import { ReactComponent as ReturnIcon } from "Assets/Icons/Returns.svg";
import { ReactComponent as ItemIcon } from "Assets/Icons/Item.svg";

const Details = ({ data, isSupervisor, user }) => (
    <React.Fragment>
        {data.consignments && <div className="order-card">
            <div className="header">
                <div className="icon">
                    <ConsignmentsIcon />
                </div>
                Consignments
            </div>
            {data.consignments.map((consignment, i) => <Consignment key={`consignment-${i}`} data={consignment} code={data.code} items={data.entries} />)}
        </div>}
        {data.tickets && data.tickets.length > 0 && <div className="order-card">
            <div className="header">
                <div className="icon">
                    <ItemIcon />
                </div>
                Tickets
            </div>
            {data.tickets.map((ticket, i) => <Ticket key={`ticket-${i}`} data={ticket}/>)}
        </div>}
        {data.user && <div className="order-card">
            <div className="header">
                <div className="icon">
                    <CustomerIcon />
                </div>
                Customer
            </div>
            <Customer data={data.user} store={data.store} sales={data.salesApplication}/>
        </div>}
        {data.returnRequests.length > 0 && <div className="order-card">
            <div className="header">
                <div className="icon">
                    <ReturnIcon />
                </div>
                Return Requests
            </div>
            {data.returnRequests.map((returns, i) => <ReturnRequest key={`returns-${i}`} isSupervisor={isSupervisor} data={returns} order={data.code}/>)}
        </div>}
    </React.Fragment>
);

Details.propTypes = {
    data: PropTypes.object.isRequired,
    isSupervisor: PropTypes.bool,
    user: PropTypes.string,
};

export default Details;